@use "variables" as * 
@use "mixin" as *

// コンテナ
.contentContainer
  @include pc
    max-width: 960px
    margin: 0 auto

// セクション設定リセット
.s-reset
  @include pc
    width: auto
    margin: 0

// トップのタイトル
.topTitle
  text-align: center
  width: 100%
  padding: 24px 0
  font-size: 24px
  box-shadow: 0px 4px 10px $gray
  z-index: 100

.inner
  width: 100%
  height: 100%
  background: rgba(255, 255, 255, 0.7)

.mainTitle
  font-family: "Oswald", sans-serif
  font-size: 56px
  text-shadow: 8px 8px 0px rgba(21, 30, 130, 0.25), 4px 4px 0px rgba(21, 30, 130, 0.5)
  padding-top: 24px
  @include pc
    padding-top: 0

.sub-title
  color: $white
  text-shadow: -2px -2px 1px $black, -2px 0 1px $black, -2px 2px 1px $black, 0 -2px 1px $black, 0 2px 1px $black, 2px -2px 1px $black, 2px 0 1px $black, 2px 2px 1px $black, 4px 6px 4px rgb(0 0 0 / 50%)

.whitePaper
  background: rgba(255, 255, 255, 0.8)
  box-shadow: -4px 4px 8px rgba(255, 255, 255, 0.5), 4px 4px 8px rgba(255, 255, 255, 0.5)

.mainSection
  @include pc
    margin: 0 auto
    max-width: 100%
    width: 960px

// 100%幅
.f-basis-100
  flex-basis: 100% !important

// 35%幅
.w-35
  width: 35% !important

// 40%幅
.w-40
  width: 40% !important

// 80%幅
.w-80
  width: 80% !important

// 90%幅
.w-90
  width: 90% !important

// flexのjustify-content: space-between
.flex-between
  display: flex
  justify-content: space-between

.flex-around
  display: flex
  justify-content: space-around

.flex-center
  display: flex
  justify-content: center

// max-content
.max-content
  width: max-content

// ボタン
.btn
  display: block
  border-radius: 0
  border: 1px solid $gray
  box-shadow: 2px 4px $gray
  width: fit-content

// ピンクボタン
  &-primary
    color: $white
    &:hover
      color: $white

.pointer-events-none
  pointer-events: none

.min-h-80vh
  min-height: 80vh

.min-w-150
  min-width: 150px

.min-w-200
  min-width: 200px

.min-w-350
  min-width: 350px

.min-w-400
  min-width: 400px

.max-w-150
  max-width: 150px

.max-w-200
  max-width: 200px

.max-w-220
  max-width: 220px

.max-w-350
  max-width: 350px

.max-w-400
  max-width: 400px

.max-w-100
  max-width: 100%

.w-150
  width: 150px

.w-250
  width: 250px

.w-350
  width: 350px

// 一部のスマホで横幅が足りなくなるので基本的に利用しない
.w-400
  width: 400px

// heightに38pxを設定
.h-38
  height: 38px

// heightに150pxを設定
.h-150
  height: 150px

// heightに200pxを設定
.h-200
  height: 200px

// heightに500pxを設定
.h-500
  height: 500px

// アイコンの色を白に
.icon-white
  filter: invert(88%) sepia(61%) saturate(0%) hue-rotate(229deg) brightness(107%) contrast(101%)

// 画像をグレーに
.grayscale-100
  filter: grayscale(100%)

// aタグの設定
.a-reset
  color: $white
  display: block
  text-decoration: none
  &:hover
    color: $white

// 選手カードのシャドウ
.card-shadow
  box-shadow: 6px 6px 2px 2px $dark

// 文字のシャドウ
.text-shadow
  text-shadow: 1px 1px $secondary

// モーダル内の背景色がはみ出ないように
.modal-content
  max-height: 90vh
  max-width: 700px
  margin: auto

.modal-body
  overflow-y: auto

div.modal
  padding: 0 !important

.z-index-1
  z-index: 1

.mt--1px
  margin-top: -1px !important

.mosaic
  -ms-filter: blur(6px)
  filter: blur(6px) grayscale(100%)

.text-rainbow
  background-image: linear-gradient(45deg, #f00 0%, #f80 14.28%, #dd0 28.56%, #0d0 42.85%, #0dd 57.14%, #00f 71.42%, #e0e 85.71%, #f00 100%)