@import "variables.sass"
@import "mixin"

html
  color: $black
  background: $white

body
  min-height: 80vh
  display: flex
  flex-direction: column
  padding: 0 !important

.body
  min-height: calc(100vh - 263px)
  display: flex
  flex-direction: column
  padding: 0 !important

*
  margin: 0
  padding: 0
  box-sizing: border-box

section
  flex-grow: 1
  @include pc
    width: 960px
    max-width: 100%
    margin: 0 auto

table
  border-collapse: collapse
  border-spacing: 0

fieldset,
img
  border: 0
  pointer-events: none
  -webkit-touch-callout: none
  -webkit-user-select: none
  -moz-touch-callout: none
  -moz-user-select: none
  touch-callout: none
  user-select: none

address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var
  font-style: normal
  font-weight: normal

ol,
ul
  list-style: none

caption,
th
  text-align: left

q:before,
q:after
  content: ""

abbr,
acronym 
  border: 0
  font-variant: normal

sup
  vertical-align: text-top

sub
  vertical-align: text-bottom

input,
textarea,
select
  font-family: inherit
  font-size: inherit
  font-weight: inherit
  *font-size: 100%

p
  margin: 0
