#gacha-result
  overflow-x: hidden
  background-size: cover
  background-position: center
  display: block
  text-align: center
  position: relative
  > .background-effect
    background-size: cover
    background-position: center
    height: 100%
    width: 100%
    position: absolute
  > #result-title,#result-name
    visibility: hidden
  > .getText
    display: inline-block
    backdrop-filter: blur(2px)
    background-clip: text
    color: transparent
  > .normal
    background-image: linear-gradient(45deg, #b8751e 0%, #ffce08 37%, #fefeb2 47%, #fafad6 50%, #fefeb2 53%, #e1ce08 63%, #b8751e 100%)
  > .rare
    background-image: linear-gradient(45deg, #f00 0%, #f80 14.28%, #dd0 28.56%, #0d0 42.85%, #0dd 57.14%, #00f 71.42%, #e0e 85.71%, #f00 100%)
  > .card-drow-animation
    perspective: 1000px
    -webkit-perspective: 1000px
    > .cardArea
      position: relative
      height: 350px
      animation: fade 0.8s cubic-bezier(0.5, 0.05, 0.8, 0.1) forwards
      > .front,.back
        position: absolute
        top: 0
        left: 0
        right: 0
        margin: auto
        height: 100%
        transition: transform 1s
        -webkit-backface-visibility: hidden
        backface-visibility: hidden
        object-fit: contain
        // filter: drop-shadow(4px 4px 6px black)
      > .front
        cursor: pointer
      > .back
        transform: rotateY(-180deg)
    // > .NormalEffect
    //   filter: drop-shadow(4px 4px 6px black)
    // > .RareEffect1
    //   animation: rareDropShadow 3s linear infinite
      > .text-shadow-normal
        font-weight: 700
        font-size: 2.5rem
        color: #fff
        animation: normalTextShadow 4s linear infinite, flow-animation 3s linear infinite
      > .text-shadow-rare
        font-weight: 700
        font-size: 2.5rem
        color: #fff
        animation: rareTextShadow 3s linear infinite, flow-animation 3s linear infinite
    
    > .flipped
      > .front
        transform: rotateY(180deg)
      > .back
        transform: rotateY(360deg)
    > .flip
      > .front
        transform: rotateY(0deg)
      > .back
        transform: rotateY(0deg)

    @keyframes fade
      0%
        transform: rotatex(75deg) translate3d(0px, -500px, -100px)
      100%
        transform: rotatex(0deg) translate3d(0px, 0px, 0px)
    @keyframes normalTextShadow
      0%, 100%
        text-shadow: 1px 1px 1px rgb(0 0 0), 1px -1px 1px rgb(0 0 0), -1px 1px 1px rgb(0 0 0), -1px -1px 1px rgb(0 0 0), 0px 0px 30px Cyan, 0px 0px 30px Cyan
      50%
        text-shadow: 1px 1px 1px rgb(0 0 0), 1px -1px 1px rgb(0 0 0), -1px 1px 1px rgb(0 0 0), -1px -1px 1px rgb(0 0 0), 0px 0px 15px #b7ffff, 0px 0px 15px #b7ffff
    @keyframes rareTextShadow
      0%, 100%
        text-shadow: 1px 1px 1px rgb(0 0 0), 1px -1px 1px rgb(0 0 0), -1px 1px 1px rgb(0 0 0), -1px -1px 1px rgb(0 0 0), 0px 0px 30px Magenta, 0px 0px 30px Magenta, 0px 0px 30px Magenta
      33%
        text-shadow: 1px 1px 1px rgb(0 0 0), 1px -1px 1px rgb(0 0 0), -1px 1px 1px rgb(0 0 0), -1px -1px 1px rgb(0 0 0), 0px 0px 30px yellow, 0px 0px 30px yellow, 0px 0px 30px yellow
      66%
        text-shadow: 1px 1px 1px rgb(0 0 0), 1px -1px 1px rgb(0 0 0), -1px 1
