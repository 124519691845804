@use "variables"

$base-font-family: "HGSｺﾞｼｯｸM"

$sm: 600px

// pc版
@mixin pc
    @media (min-width: ($sm))
        @content

@mixin mainImg($img)
    background-image: url($img)
    background-size: cover
    display: block

@mixin carouselButton
    position: absolute
    top: 50%
    -webkit-transform: translate(0, -50%)
    transform: translate(0, -50%)
    width: 30px
    height: 30px
    background-color: $white
    border: 3px solid $dark
    border-radius: 50%
    opacity: 1
    &::before
        content: ""
        position: absolute
        top: 50%
        width: 10px
        height: 10px
        border-top: 3px solid $dark
        border-right: 3px solid $dark
        margin-top: -5px
