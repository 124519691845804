// Body
$body-bg: #fff
$body-color: #000

// Typography
$font-family-sans-serif: "Nunito", sans-serif
$font-size-base: 0.9rem
$line-height-base: 1.6

// Colors
$blue: #3490dc
$indigo: #6574cd
$purple: #9561e2
$pink: #f66d9b
$red: #e3342f
$orange: #f6993f
$yellow: #ffed4a
$green: #38c172
$teal: #4dc0b5
$cyan: #6cb2eb
$accent: #f6e43e
$error: #ff3434
$gray: #ddd
$gray-600: #6c757d
$dark: #343a40
$black: #000
$white: #fff

$primary: #000
$secondary: #000

$default-primary: #000
$default-secondary: #000

$cerezo-primary: #ca1558
$cerezo-secondary: #121a6f

$albirex-primary: #f15921
$albirex-secondary: #0d4da1

$evessa-primary: #e60012
$evessa-secondary: #b18a47

$kobesteelers-primary: #dc000c
$kobesteelers-secondary: #dc000c

$verblitz-primary: #00441b
$verblitz-secondary: #c8a255

$vissel-primary: #a41c32
$vissel-secondary: #000

$grampus-primary: #d80c18
$grampus-secondary: #f8b600

$fctokyo-primary: #000a6e
$fctokyo-secondary: #fc0000

$sanfrecce-primary: #51318f
$sanfrecce-secondary: #bf9b47

$s-pulse-primary: #FF8709
$s-pulse-secondary: #002d55

$jubilo-primary: #7399d1
$jubilo-secondary: #002844

$v-varen-primary: #005bac
$v-varen-secondary: #ffa207

$urawa-reds-primary: #e6002d
$urawa-reds-secondary: #000

$theme-colors: ("primary": $primary,"secondary": $secondary,"default-primary": $default-primary,"default-secondary": $default-secondary,"cerezo-primary": $cerezo-primary,"cerezo-secondary": $cerezo-secondary,"cerezo-ladies-primary": $cerezo-primary,"cerezo-ladies-secondary": $cerezo-secondary,"albirex-primary": $albirex-primary,"albirex-secondary": $albirex-secondary,"evessa-primary": $evessa-primary,"evessa-secondary": $evessa-secondary,"kobesteelers-primary": $kobesteelers-primary,"kobesteelers-secondary": $kobesteelers-secondary,"verblitz-primary": $verblitz-primary,"verblitz-secondary": $verblitz-secondary,"vissel-primary": $vissel-primary,"vissel-secondary": $vissel-secondary,"grampus-primary": $grampus-primary,"grampus-secondary": $grampus-secondary,"sanfrecce-primary": $sanfrecce-primary,"sanfrecce-secondary": $sanfrecce-secondary,"s-pulse-primary": $s-pulse-primary,"s-pulse-secondary": $s-pulse-secondary,"fctokyo-primary": $fctokyo-primary,"fctokyo-secondary": $fctokyo-secondary,"jubilo-primary": $jubilo-primary,"jubilo-secondary": $jubilo-secondary,"v-varen-primary": $v-varen-primary,"v-varen-secondary": $v-varen-secondary,"urawa-reds-primary": $urawa-reds-primary,"urawa-reds-secondary": $urawa-reds-secondary,"warning": $accent,"danger": $error,"gray": $gray,"dark": $dark,"cleargray": rgb(211, 211, 211, 0.5),"gray-600":$gray-600,"white": #fff,"black": #000,"blue":$blue)

$border-width: 2px
$border-color: $gray
$border-radius-lg: 20px

$table-border-width: 1px

$font-sizes: ("title": 64px,"title2": 32px,"subtitle": 20px,"topic": 18px,"body": 16px,"description": 14px,"caption": 12px,"small": 10px,)

@import '~bootstrap/scss/bootstrap'
